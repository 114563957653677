<template>
  <div v-if="step == 1" class="settingStep">
    <div class="container" style="padding: 10px; margin-bottom: 20px">
      <el-button type="primary" @click="printPreview">
        <el-icon>
          <document />
        </el-icon>
        Print Preview
      </el-button>
    </div>
    <div class="settingTemplate">
      <el-descriptions border title="Setting" direction="vertical" :column="4">
        <el-descriptions-item label="Classroom">
          <el-select :disabled="this.form.receiptId && this.form.receiptId.length == 22" v-model="form.roomId"
            placeholder="Please select..." @change="handleClassRoomChange">
            <el-option v-for="item in classRoomList" :key="item.roomId" :label="item.name" :value="item.roomId"
              :style="`background-color:${item.activeStatus === 1024 ? this.unstartBGColor : item.activeStatus === 2048 ? this.activeBGColor : '#FFF'};`"></el-option>
          </el-select>
          <div :style="`margin-left:10px;padding:5px;display: inline-block;background-color: ${this.activeBGColor};`">
            Active</div>
          <div :style="`margin-left:10px;padding:5px;display: inline-block;background-color: ${this.unstartBGColor};`">
            Unstarted
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="Bill To">
          <el-select v-model="form.studentId" remote filterable clearable :reserve-keyword="false"
            :disabled="this.form.receiptId && this.form.receiptId.length == 22" placeholder="Please enter a keyword"
            :remote-method="filterStudentsByName" @change="handleSelectStudent" :loading="loadingStudents"
            class="studentInput">
            <el-option v-for="item in studentList" :key="item.studentId" :label="item.username"
              :value="item.studentId" />
          </el-select>
          <el-checkbox v-if="!(this.form.receiptId && this.form.receiptId.length == 22)"
            v-model="this.form.enabledAddStudentIntoClassRoom" style="margin-left: 10px">
            Add to ClassRoom if not?
          </el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="Bill date">
          <el-date-picker type="date" placeholder="Select Bill Date" v-model="form.billDate"></el-date-picker>
        </el-descriptions-item>
        <el-descriptions-item label="GST Rate(%)">
          <el-input type="number" :step="1" v-model="form.gst"
            @change="handleGSTRateChange"></el-input></el-descriptions-item>
        <el-descriptions-item label="Sibling Discount(%)">
          <el-input-number v-model="form.siblingDiscount" :min="0" :max="100" :step="1" controls-position="right"
            @change="handleSiblingDiscountChange"></el-input-number>
        </el-descriptions-item>
        <el-descriptions-item label="Special Discount(%)">
          <el-input-number v-model="form.specialDiscount" :min="0" :max="100" :step="1" controls-position="right"
            @change="handleSpecialDiscountChange"></el-input-number>
        </el-descriptions-item>
        <el-descriptions-item label="Tier Discount(%)">
          <el-checkbox v-model="form.enabledPercentileDiscount"
            @change="handleEnabledPercentileDiscount('PercentileDiscount', $event)"></el-checkbox>
          <el-input-number v-model="form.percentileDiscount" :disabled="!form.enabledPercentileDiscount" :min="0"
            :max="100" :step="1" controls-position="right" style="margin-left: 10px"
            @change="handlePercentileDiscountChange($event)"></el-input-number>
        </el-descriptions-item>
        <el-descriptions-item label="EarlyBird">
          <el-checkbox v-model="this.form.enabledEarlyBirdPromotionType"
            @change="addPromotionDiscount('EarlyBird', $event)"></el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="Discount(%)">
          <el-input-number :min="0" :max="100" :step="1" :disabled="true" v-model="form.discount"
            controls-position="right" @change="handleDiscountChange"></el-input-number>
        </el-descriptions-item>
        <el-descriptions-item label="Enrollment Fee">
          <el-checkbox v-model="this.specialItemValues.EnrollmentFee"
            @change="handleEnrolmentFee($event)"></el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="Royal Points">
          <el-checkbox @change="handleEnableRoyalPointsChange" v-model="this.form.enableRoyalPoints"></el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="Royal Discount">
          <el-checkbox @change="handleEnableRoyalDiscountChange" v-model="this.form.enableRoyalDiscount"></el-checkbox>
        </el-descriptions-item>
        <el-descriptions-item label="Lesson Count">
          <el-select :disabled="this.form.receiptId && this.form.receiptId.length == 22"
            @change="handleLessonCountChange" v-model="form.lessonCount" placeholder="Please select...">
            <el-option v-for="item in options.lessonCountList" :key="item.key" :label="item.value"
              :value="item.key"></el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="Bonus Term Points" v-if="false">
          <el-checkbox v-model="form.enableTermBonusPoints" @change="
            handleSpecialPointsChange(
              'ENROLMENT_TERM_BONUS',
              this.form.enableTermBonusPoints,
              false
            )
            "></el-checkbox>
          <el-input-number v-model="form.enrolmentPoints" :disabled="!form.enableTermBonusPoints" :min="0"
            controls-position="right" style="margin-left: 10px" @change="
              handleSpecialPointsChange(
                'ENROLMENT_TERM_BONUS',
                this.form.enableTermBonusPoints,
                false,
                $event
              )
              "></el-input-number>
        </el-descriptions-item>
        <el-descriptions-item
          :label="`Redeem Point (${this.form.studentName} owns ${this.form.studentPoints} points${this.form.redeemedPoints ? ', ' + (this.form.studentPoints - this.form.redeemedPoints) + ' left)' : ')'}`">
          <el-checkbox :disabled="this.form.receiptId && this.form.receiptId.length == 22"
            v-model="form.enableRedeemPoints"></el-checkbox>
          Redeem <el-input-number v-model="form.redeemedPoints"
            :disabled="!form.enableRedeemPoints || (this.form.receiptId && this.form.receiptId.length == 22)" :min="0"
            :step="500" :max="this.form.studentPoints" controls-position="right" style="margin-left: 10px"
            @change="handleRedeemPointsChange($event)"></el-input-number>
          to ${{ this.form.redeemedAmount }}
        </el-descriptions-item>
        <el-descriptions-item label="Credit Deduction">
          <el-input-number v-model="form.creditDeduction" :min="0" :max="1000" :step="10" :controls="false"
            @change="handleCreditDeductionChange" controls-position="right"></el-input-number>
        </el-descriptions-item>
      </el-descriptions>
      <div class="studentInfoPanel">
        <ul>
          <li v-if="this.student">
            RollDate:
            {{ this.$formatter.formatDate(this.student.enrolledTime) }}, Royal Years:
            <b>{{ this.getLoyaltyYears() }}</b>.
          </li>
          <li v-if="this.latestTestReport">
            Last Test Report Summary: Name (this.latestTestReport.testReport.name), Total
            ({{ this.latestTestReport.total }}), Percentile (<span style="font-weight: bold"
              v-html="this.latestTestReport.totalRank.toStringWithOrdinalSup()"></span>)
          </li>
        </ul>
      </div>
    </div>
    <div class="discountItems">
      <el-descriptions border title="Discount Items" direction="horizontal">
        <div v-for="item in this.form.discountItems" :key="item.name">
          <el-descriptions-item>
            <template #label>
              <el-icon>
                <calendar />
              </el-icon>
              {{ item.name }}
            </template>
            {{ item.discount }}%
          </el-descriptions-item>
        </div>
      </el-descriptions>
    </div>
    <div class="discountItems">
      <el-descriptions border title="Points Item to Be Issued" direction="horizontal">
        <div v-for="item in this.form.pointsItems" :key="item.name">
          <el-descriptions-item>
            <template #label>
              <el-icon>
                <coin />
              </el-icon>
              {{ item.title }}
            </template>
            {{ item.points }}
            {{ item.issuedType !== "Normal" ? `${item.issuedType} Points` : "Points" }}
          </el-descriptions-item>
        </div>
      </el-descriptions>
    </div>
    <div class="courseTemplate">
      <h2 class="el-descriptions__title" style="margin-top: 20px">Courses</h2>
      <el-select v-model="needToAddCourse" value-key="courseId" class="courseSelect" placeholder="Add a course">
        <el-option v-for="item in courseList" :key="item.courseId" :label="item.title" :value="item"></el-option>
      </el-select>
      <el-button @click="handleAddCourse">
        <el-icon>
          <plus />
        </el-icon>
      </el-button>
      <el-table :data="this.form.items" border class="courseListTable">
        <el-table-column label="Description">
          <template #default="scope">
            <el-input v-model="scope.row.title" class="courseNameInput"></el-input>
            <el-select v-model="scope.row.term" class="termSelect" placeholder="Select Term">
              <el-option v-for="item in this.options.termList" :key="item.value" :label="item.description"
                :value="item.value"></el-option>
            </el-select>
            <el-input v-model="scope.row.comment" class="commentInput"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Quantity" :width="this.$widthRatio * 120 + 'px'">
          <template #default="scope">
            <el-input type="number" v-model="scope.row.quantity"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Hour Rate" :width="this.$widthRatio * 120 + 'px'">
          <template #default="scope">
            <el-input type="number" v-model="scope.row.unitPrice"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="Subtotal (Incl.GST)" :width="this.$widthRatio * 140 + 'px'">
          <template #default="scope">
            {{ this.$formatter.formatDecimal(this.calculateItemTotal(scope.row)) }}
          </template>
        </el-table-column>
        <el-table-column label="GST" :width="this.$widthRatio * 90 + 'px'">
          <template #default="scope">
            {{ this.$formatter.formatDecimal(this.calculateItemGST(scope.row)) }}
          </template>
        </el-table-column>
        <el-table-column label="Operation" :width="this.$widthRatio * 180 + 'px'" align="center">
          <template #default="scope">
            <el-button size="mini" @click="handleDeleteItem(scope.$index, scope.row)">
              <el-icon>
                <delete />
              </el-icon></el-button>
            <el-button size="mini" @click="handleMoveUpItem(scope.$index, scope.row)">
              <el-icon>
                <top />
              </el-icon>
            </el-button>
            <el-button size="mini" @click="handleMoveDownItem(scope.$index, scope.row)">
              <el-icon>
                <bottom />
              </el-icon>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-descriptions border :column="1">
        <el-descriptions-item label="Total Subtotal">
          ${{ this.$formatter.formatDecimal(this.calculateNetTotal()) }}
        </el-descriptions-item>
        <el-descriptions-item label="Discount">
          ${{ this.$formatter.formatDecimal(this.calculateDiscountAmount()) }}
        </el-descriptions-item>
        <el-descriptions-item label="Redeemed Amount" v-if="this.form.redeemedAmount > 0">
          -${{ this.$formatter.formatDecimal(this.form.redeemedAmount) }}
        </el-descriptions-item>
        <el-descriptions-item label="Credit Deduction" v-if="this.form.creditDeduction > 0">
          -${{ this.$formatter.formatDecimal(this.form.creditDeduction) }}
        </el-descriptions-item>
        <el-descriptions-item label="TOTAL (Round Up)">
          ${{ this.$formatter.formatDecimal(this.calculateTotal()) }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
  <div v-else class="printReviewStep">
    <div class="container" style="padding: 10px; margin-bottom: 20px">
      <el-button type="primary" @click="previousStep">
        <el-icon>
          <back />
        </el-icon>
        Previous
      </el-button>
      <el-button type="primary" @click="saveAndPrintReceipt">
        <el-icon>
          <printer />
        </el-icon>
        Save &amp; Print
      </el-button>
    </div>
    <div id="printArea" class="printArea">
      <div class="header">
        <div class="leftPart">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td class="companyName">{{ form.companyName }}</td>
            </tr>
            <tr>
              <td>{{ form.detailedAddress }}</td>
            </tr>
            <tr>
              <td>{{ form.suburb }}, {{ form.postcode }}</td>
            </tr>
            <tr>
              <td>Phone: {{ form.phone }}</td>
            </tr>
            <tr>
              <td>ABN: {{ form.abn }}</td>
            </tr>
            <tr>
              <td>Website: {{ form.website }}</td>
            </tr>
          </table>
        </div>
        <div class="rightPart">
          <div class="templateAndLog">
            <div class="templateName">{{ form.templateName }}</div>
            <div class="logo"><img src="../../assets/img/je_logo.png" /></div>
          </div>
          <div class="billToDate">
            DATE
            <span class="date">{{ this.$formatter.formatDate(form.billDate) }}</span>
          </div>
        </div>
      </div>
      <div class="billTo">
        <div class="title" style="background-color: #3b4e87">BILL TO</div>
        <div class="name">{{ form.billTo }}</div>
      </div>
      <div class="items">
        <table border="0" cellpadding="0" cellspacing="0">
          <tr>
            <th style="width: 50%; background-color: #3b4e87">DESCRIPTION</th>
            <th style="width: 30%; background-color: #3b4e87">Subtotal</th>
            <th v-if="false" style="width: 20%; background-color: #3b4e87">GST</th>
          </tr>
          <tr v-for="(item, index) in getCombinedItems()" :key="index" :class="index % 2 == 0 ? '' : 'odd'">
            <td>
              <span v-html="item.titleHTML"></span>
            </td>
            <td>
              <span v-html="item.totalHTML"></span>
            </td>
            <td v-if="false">
              {{ item.gstAmount ? this.$formatter.formatDecimal(item.gstAmount) : "" }}
            </td>
          </tr>
          <tr v-for="n in 16 - getCombinedItemsCount()" :key="n"
            :class="(n + 1 + getCombinedItemsCount()) % 2 == 0 ? '' : 'odd'">
            <td></td>
            <td></td>
            <td v-if="false"></td>
          </tr>
        </table>
      </div>

      <div class="summary">
        <div class="leftPart">
          <div class="title" style="background: #3b4e87">OTHER COMMENTS</div>
          <ul>
            <li v-for="(item, index) in form.comments" :key="index">
              {{ index + 1 }}. {{ item }}
            </li>
          </ul>
        </div>
        <div class="rightPart">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td>Total Subtotal</td>
              <td class="value">${{ this.$formatter.formatDecimal(form.netTotal) }}</td>
            </tr>
            <tr>
              <td class="discount">Discount</td>
              <td class="discount value">
                ${{ this.$formatter.formatDecimal(form.discountAmount) }}
              </td>
            </tr>
            <tr>
              <td class="total">TOTAL</td>
              <td class="total value" style="background-color: #c6efce; color: #006100">
                ${{ this.$formatter.formatDecimal(form.total) }}
              </td>
            </tr>
          </table>

          <!-- <div class="paymentMethods">
            <div class="title" style="background: #3b4e87;">
              Payment Methods
            </div>
            <ul>
              <li v-for="(item, index) in form.paymentMethods" :key="index">
                {{ index + 1 }}. {{ item }}
              </li>
            </ul>
          </div> -->
        </div>
      </div>
      <div class="footer">
        <div class="container">
          <div class="contactDetail">{{ form.contact }}</div>
          <div class="compliment">{{ form.compliment }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getData, putData, postData } from "../../service/api";

export default {
  name: "buildReceiptTemplate",
  data() {
    return {
      controllerUrl: "/receipt",
      step: 1,
      options: {
        termList: [],
        pointsTemplateList: [],
        lessonCountList: [],
      },
      combinedItemsCount: 0,
      loadingStudents: false,
      needToAddCourse: "",
      royalDiscountName: "RoyalDiscount",
      specialDiscountName: "SpecialDiscount",
      siblingDiscountName: "SiblingDiscount",
      percentileDiscountName: "TierDiscount",
      promotionItems: [
        {
          name: "Normal",
          isSuccessive: false,
          discount: 0,
        },
        {
          name: "EarlyBird",
          isSuccessive: false,
          discount: 15,
        },
      ],
      specialItemValues: { EnrollmentFee: false, BookletFee: 0 },
      specialItemOptions: { BookletFee: [] },
      specialPointsItem: [
        {
          name: "ENROLMENT_TERM_BONUS",
          title: "Bonus term points",
          issuedType: "Normal",
          points: 0,
          fieldInForm: "enrolmentPoints",
          calculatePoints: this.calculateEnrolmentPoints,
        },
        {
          name: "ENROLMENT",
          title: "Points for new enrolment",
          issuedType: "Normal",
          points: 0,
          fieldInForm: null,
          calculatePoints: null,
        },
      ],
      specialItems: [
        {
          id: "EnrollmentFee",
          title: "Enrollment fee",
          type: "Enrollment",
          controlType: "checkbox",
          useGlobalTerm: true,
          term: "",
          unitPrice: 20,
          quantity: 1,
          comment: "",
          useGlobalGst: false,
          gst: 0,
          useGlobalDiscount: false,
          useGlobalSuccessiveDiscount: false,
          successiveDiscount: 0,
          discount: 0,
        },
        {
          id: "BookletFee",
          title: "Booklet fee",
          type: "Booklet",
          controlType: "select",
          useGlobalTerm: true,
          term: "",
          unitPrice: [20, 25],
          quantity: 1,
          comment: "",
          useGlobalGst: false,
          gst: 0,
          useGlobalDiscount: false,
          useGlobalSuccessiveDiscount: false,
          successiveDiscount: 0,
          discount: 0,
        },
      ],
      classRoomList: [],
      courseList: [],
      studentList: [],
      student: {},
      purchasedRecords: [],
      latestTestReport: null,
      activeBGColor: "#73d0ff",
      unstartBGColor: "#9cdb9c",
      form: {
        receiptId: "",
        studentId: "",
        studentName: "",
        roomId: "",
        roomName: "",
        grade: "",
        term: "",
        gst: 10,
        studentPoints: 0,
        redeemedPoints: 0,
        redeemedAmount: 0,
        bookletFee: 0,
        enabledAddStudentIntoClassRoom: true,
        enabledEarlyBirdPromotionType: false,
        enableTermBonusPoints: false,
        enableRedeemPoints: false,
        enableRoyalPoints: true,
        enableRoyalDiscount: false,
        enableEnrolmentPoints: true,
        enabledPercentileDiscount: false,
        loyaltyYears: 0,
        loyalDiscount: 0,
        siblingDiscount: 0,
        specialDiscount: 0,
        percentileDiscount: 0,
        successiveDiscount: 0, // siblingDiscount+specialDiscount+loyalDiscount
        enrolmentPoints: 0,
        lessonCount: 11,
        discount: 0,
        discountItems: [],
        pointsItems: [],
        promotionType: "Normal",
        operator: "",
        companyLogo: "../../assets/img/je_logo.png",
        templateName: "RECEIPT",
        companyName: "JE Maths Education",
        detailedAddress: "Suite 25/1 Railway Parade",
        suburb: "Burwood",
        postCode: "2134",
        phone: "0422777073",
        abn: "45169016274",
        website: "http://www.jeeducation.com.au",
        billDate: new Date(),
        billTo: "",
        items: [],
        description: "",
        note: "",
        netTotal: 0, // without gst and discount amount
        discountAmount: 0,
        gstTotal: 0,
        creditDeduction: 0,
        total: 0, // include gst + discount amount
        comments: ["No Refund! No Credit! "],
        paymentMethods: ["By Cash.", "By Eftpos."],
        contact:
          "If you have any questions about this receipt, please contact [Jonathan Hu, Phone 0422777073, jemathseducation@gmail.com]",
        compliment: "Thank You For Your Business!",
      },
    };
  },
  watch: {
    "$route.query"(newValue) {
      console.log("this.$route.query", newValue);
      this.previousStep();
      this.initAndLoadData(newValue);
    },
  },
  created() {
    this.activeBGColor = this.$appSetting.getGenericValue("CLASSROOM_ACTIVE_BGCOLOR", "#73d0ff")
    this.unstartBGColor = this.$appSetting.getGenericValue("CLASSROOM_UNSTRAT_BGCOLOR", "#9cdb9c")

    this.options.pointsTemplateList = this.$appSetting.globalConfig.pointsTemplateList;
    this.options.termList = this.$appSetting.globalConfig.termList;
    this.rebuildCourseLessonCountList(11); // 11 is default lesson count for a course
    this.initAndLoadData(this.$route.query);

    if (!this.$route.query.receiptId) {
      this.$nextTick(async function () {
        let earlyBirdChecked =
          this.$appSetting.getGenericValue("RECEIPT_EARLYBIRD_CHECKED", "1") === "1";

        this.form.enabledEarlyBirdPromotionType = earlyBirdChecked;
        this.addPromotionDiscount("EarlyBird", earlyBirdChecked);

        let royalDiscountChecked =
          this.$appSetting.getGenericValue("RECEIPT_ROYAL_DISCOUNT_CHECKED", "1") === "1";
        this.form.enableRoyalDiscount = royalDiscountChecked;
        this.handleEnableRoyalDiscountChange();
      });
    }
  },
  methods: {
    async initAndLoadData(query) {
      this.form.receiptId = null;
      this.form.studentId = null;
      let studentId = query.studentId;
      let receiptId = query.receiptId;
      let roomId = query.roomId;

      for (let i = 0; i < this.specialItems.length; i++) {
        let specialItem = this.specialItems[i];
        if (specialItem.controlType === "checkbox") {
          this.specialItemValues[specialItem.id] = false;
        } else if (specialItem.controlType === "select") {
          this.specialItemValues[specialItem.id] = 0;
          let unitPrices = specialItem.unitPrice;
          let unitPriceOptions = [{ label: "Nothing", value: 0 }];
          for (let j = 0; j < unitPrices.length; j++) {
            let unitPrice = unitPrices[j];
            unitPriceOptions.push({ label: `$${unitPrice}`, value: unitPrice });
          }
          this.specialItemOptions[specialItem.id] = unitPriceOptions;
        }
      }

      await this.loadBasicData();
      if (roomId && roomId.length == 22) {
        this.handleClassRoomChange(roomId);
      }
      if (receiptId && receiptId.length == 22) {
        this.form.receiptId = receiptId;
        this.loadData();
      } else if (studentId && studentId.length == 8) {
        this.form.studentId = studentId;
        this.loadData();
      }
    },
    loadData() {
      if (this.form.receiptId) {
        this.loadReceiptData();
      } else if (this.form.studentId) {
        this.loadStudentData();
      }
    },
    async loadBasicData() {
      await this.loadAllClassRooms();
      await this.loadCourseData();
    },
    loadReceiptData() {
      getData(`${this.controllerUrl}/${this.form.receiptId}`, null)
        .then((res) => {
          if (res.result && res.code === "200") {
            for (let pro in res.result) {
              this.form[pro] = res.result[pro];
            }
            for (let i = 0; i < this.form.items.length; i++) {
              let item = this.form.items[i];
              let specialItem = this.specialItems.find((e) => e.id === item.id);
              if (specialItem) {
                if (specialItem.controlType === "checkbox") {
                  this.specialItemValues[specialItem.id] = true;
                } else if (specialItem.controlType === "select") {
                  this.specialItemValues[specialItem.id] = item.unitPrice;
                }
              }
            }
            let siblingDiscountItem = this.form.discountItems.find((e) => {
              return e.name === this.siblingDiscountName;
            });
            if (siblingDiscountItem) {
              this.form.siblingDiscount = siblingDiscountItem.discount;
            }
            let specialDiscountItem = this.form.discountItems.find((e) => {
              return e.name === this.specialDiscountName;
            });
            if (specialDiscountItem) {
              this.form.specialDiscount = specialDiscountItem.discount;
            }
            let loyalDiscountItem = this.form.discountItems.find((e) => {
              return e.name === this.royalDiscountName;
            });
            if (loyalDiscountItem) {
              this.form.loyalDiscount = loyalDiscountItem.discount;
            }
            let earlyBirdPromotionType = this.form.discountItems.find((e) => {
              return e.name === "EarlyBird";
            });
            if (earlyBirdPromotionType) {
              this.form.enabledEarlyBirdPromotionType = true;
            }
            if ((res.result.redeemedPoints || 0) > 0) {
              if ((this.form.studentPoints || 0) <= 0) {
                this.form.studentPoints = res.result.redeemedPoints;
              }
              this.form.redeemedPoints = res.result.redeemedPoints;
              this.form.enableRedeemPoints = true;
            }
            if (res.result.studentId) {
              this.form.studentId = res.result.studentId;
              this.loadStudentData();
              this.getStudentPoints();
            }
          } else {
            this.$message.error(
              "Fetch student data failed, error message: " + res.message
            );
          }
        })
        .catch(() => { });
    },
    loadStudentData() {
      // load student information
      getData(`/student/${this.form.studentId}`, null)
        .then((res) => {
          if (res.result && res.code === "200") {
            this.studentList = [res.result];
            this.selectStudent(res.result);
          } else {
            this.$message.error(
              "Fetch student data failed, error message: " + res.message
            );
          }
        })
        .catch(() => { });
    },
    getStudentLastTestReportWithRecords() {
      getData(
        `/testReport/getStudentLastTestReportWithRecords/${this.form.studentId}`,
        null
      )
        .then((res) => {
          if (res.result && res.code === "200") {
            this.latestTestReport = res.result;
            this.form.percentileDiscount = 0;
            if (this.latestTestReport.totalRank >= 95) {
              this.form.percentileDiscount = 50;
            } else if (
              this.latestTestReport.totalRank >= 85 &&
              this.latestTestReport.totalRank < 95
            ) {
              this.form.percentileDiscount = 30;
            }
          } else {
            // this.$message.error(
            //   "Fetch test report data failed, error message: " + res.message
            // );
          }
        })
        .catch(() => { });
    },
    getStudentPoints() {
      getData(
        `/points/student/${this.form.studentId}`,
        null
      )
        .then((res) => {
          if (res.result && res.code === "200") {
            this.form.studentPoints = res.result.points;
          } else {
            this.$message.error(
              "Fetch student points failed, error message: " + res.message
            );
          }
        })
        .catch(() => { });
    },
    async loadCourseData() {
      let sortDataArr = this.$user
        .getProfile(`course_list_sort_${this.$user.getUsername()}`, "CreatedTime|ASC")
        .split("|");
      let res = await getData("/course", {
        orderBy: sortDataArr[0],
        orderDirection: sortDataArr[1],
        pageIndex: 1,
        pageSize: 0x7fffffff,
      });
      if (res) {
        let resData = res.result;
        this.courseList = resData.list;
      }
    },
    async loadAllClassRooms() {
      let res = await getData("classRoom/getAllClassRoomIdAndNameForReceipt");
      if (res) {
        this.classRoomList = res.result;
        // sort classrooms via grade asc, term desc
        this.classRoomList.sort((x, y) => {
          return (
            (x.activeStatus - y.activeStatus) * 100000 +
            ((parseInt(x.name) || 0) - (parseInt(y.name) || 0)) * 10000 +
            x.name.localeCompare(y.name)
          );
        });
      }
    },
    selectStudent(student) {
      this.student = student;
      if (!this.form.receiptId) {
        this.addLoyaltyYearsDiscount();
        this.form.studentId = student.studentId;
        this.form.studentName = student.username;
        this.form.billTo = `${student.firstName} ${student.lastName}`;
        this.handleEnableRoyalPointsChange();
        this.handleEnableRoyalDiscountChange();
        this.handleSpecialPointsChange(
          "ENROLMENT_TERM_BONUS",
          this.form.enableTermBonusPoints,
          false
        );

        // load student last report and against record
        this.getStudentLastTestReportWithRecords();
        // load studet points
        this.getStudentPoints();
      }
    },
    filterStudentsByName(keywords) {
      this.loadingStudents = true;
      getData(
        "/student",
        {
          pageIndex: 1,
          pageSize: 10,
          username: keywords,
          status: 2048,
          orderBy: "username",
        },
        { isShowLoading: false }
      )
        .then((res) => {
          if (res.result && res.code === "200") {
            this.studentList = res.result.list || [];
            this.loadingStudents = false;
          } else {
            this.$message.error(
              "Fetch students data failed, error message: " + res.message
            );
          }
        })
        .catch(() => { });
    },
    handleSelectStudent(studentId) {
      console.log("handleSelectStudent", arguments);
      this.form.studentName = "";
      if (studentId && studentId.length === 8) {
        let student = this.studentList.find((e) => e.studentId === studentId);
        if (student) {
          this.selectStudent(student);
        }
      } else {
        this.student = {};
        this.form.studentId = "";
        this.form.studentName = "";
        this.form.billTo = "";

        this.removeIssuedPointsItem("ENROLMENT_TERM_BONUS");
        this.removeIssuedPointsItem(this.getLoyaltyYearsName());
        const name = "RoyalDiscount";
        let index = this.form.discountItems.findIndex((element) => {
          return element.name == name;
        });
        if (index >= 0) {
          this.form.discountItems.splice(index, 1);
        }
      }
    },
    handleClassRoomChange(roomId) {
      console.log("handleClassRoomChange", arguments);
      let classRoom = this.classRoomList.find((e) => e.roomId === roomId);
      if (classRoom) {
        this.form.roomId = classRoom.roomId;
        this.form.grade = classRoom.grade;
        this.form.term = classRoom.term;
        this.form.roomName = classRoom.name;

        // update lessonCount select control
        let course = this.courseList.find((e) => e.grade === classRoom.grade);
        if (course && course.lessonCount != this.options.lessonCountList.length) {
          this.rebuildCourseLessonCountList(course.lessonCount);
        }
      }
    },
    handleGSTRateChange() {
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        let specialItem = this.specialItems.find((e) => {
          return e.id === item.id;
        });
        if (!specialItem || specialItem.useGlobalGst) {
          item.gst = this.form.gst;
        }
      }
    },
    handleDiscountChange() {
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        let specialItem = this.specialItems.find((e) => {
          return e.id === item.id;
        });
        if (!specialItem || specialItem.useGlobalDiscount) {
          item.discount = this.form.discount;
        }
      }
    },
    handleSiblingDiscountChange() {
      let siblingDiscount = this.form.siblingDiscount;
      this.addOrUpdateSuccessiveDiscountItem(
        this.siblingDiscountName,
        true,
        siblingDiscount
      );
    },
    handleSpecialDiscountChange() {
      let specialDiscount = this.form.specialDiscount;
      this.addOrUpdateSuccessiveDiscountItem(
        this.specialDiscountName,
        true,
        specialDiscount
      );
    },
    handlePercentileDiscountChange(val) {
      let percentileDiscount = val;
      this.addOrUpdateSuccessiveDiscountItem(
        this.percentileDiscountName,
        true,
        percentileDiscount
      );
    },
    handleEnabledPercentileDiscount(name, enabled) {
      console.log("handleEnabledPercentileDiscount", name, enabled);
      this.form.enabledPercentileDiscount = enabled;
      if (enabled) {
        this.handlePercentileDiscountChange(this.form.percentileDiscount);
      } else {
        this.handlePercentileDiscountChange(0);
      }
    },
    addLoyaltyYearsDiscount() {
      let LoyaltyYears = this.getLoyaltyYears();
      this.form.loyalDiscount = LoyaltyYears;
      this.addOrUpdateSuccessiveDiscountItem(this.royalDiscountName, true, LoyaltyYears);
    },
    handleEnrolmentFee(val) {
      this.handleSpecialItems("EnrollmentFee", val);
      // add points
      this.handleSpecialPointsChange("ENROLMENT", val, true);
    },
    handleSpecialItems(id, val) {
      console.log("handleSpecialItems", arguments);
      let unitPrice = 0;
      let removeItem = false;
      let specialItem = this.specialItems.find((item) => {
        return item.id === id;
      });

      if (specialItem) {
        if (specialItem.controlType === "checkbox") {
          if (!val) removeItem = true;
          else unitPrice = specialItem.unitPrice;
        } else if (specialItem.controlType === "select") {
          if (val <= 0) removeItem = true;
          else unitPrice = val;
        }
        if (removeItem) {
          this.form.items = this.form.items.filter((item) => {
            return item.id !== id;
          }, this);
          return;
        }

        let item = this.form.items.find((item) => {
          return item.id === id;
        });
        if (!item) {
          item = {
            id: specialItem.id,
            title: specialItem.title,
            type: specialItem.type,
            term: specialItem.useGlobalTerm ? this.form.term : specialItem.term,
            unitPrice: unitPrice,
            quantity: specialItem.quantity,
            comment: specialItem.comment,
            gst: specialItem.useGlobalGst ? this.form.gst : specialItem.gst,
            successiveDiscount: specialItem.useGlobalSuccessiveDiscount
              ? this.form.successiveDiscount
              : specialItem.successiveDiscount,
            discount: specialItem.useGlobalDiscount
              ? this.form.discount
              : specialItem.discount,
          };
          this.form.items.push(item);
        } else {
          item.unitPrice = unitPrice;
        }
      }
    },
    handleAddCourse() {
      console.debug("needToAddCourse", this.needToAddCourse);
      let comment = this.form.promotionType;
      if (!this.needToAddCourse) return;
      let course = this.needToAddCourse;
      let lessonCount =
        course.lessonCount >= this.form.lessonCount
          ? course.lessonCount
          : this.form.lessonCount;
      let totalHours = lessonCount * course.lessonHours;
      let item = {
        id: course.courseId,
        title: course.title,
        type: "Course",
        term: this.form.term,
        unitPrice: course.unitPrice,
        quantity: totalHours,
        comment: comment,
        gst: this.form.gst,
        successiveDiscount: this.form.successiveDiscount,
        discount: this.form.discount,
      };

      if (lessonCount > this.options.lessonCountList.length) {
        this.rebuildCourseLessonCountList(lessonCount);
      }

      this.form.items.push(item);

      this.handleItemChange();
    },
    handleDeleteItem(index) {
      let items = this.form.items.splice(index, 1);
      if (items.length > 0) {
        let item = items[0];
        let specialItemVal = this.specialItemValues[item.id] || null;
        if (specialItemVal !== null) {
          if (typeof specialItemVal === "boolean") {
            this.specialItemValues[item.id] = false;
          } else if (typeof specialItemVal === "number") {
            this.specialItemValues[item.id] = 0;
          }
        }
      }

      this.handleItemChange();
    },
    handleItemChange() {
      this.handleSpecialPointsChange(
        "ENROLMENT_TERM_BONUS",
        this.form.enableTermBonusPoints,
        false
      );
      this.handleSpecialItems("BookletFee", this.calculateAndAddBookletFee());
    },
    calculateAndAddBookletFee() {
      let totalAmount = 0;
      let index = 0;
      this.form.items
        .filter((e) => e.type === "Course")
        ?.forEach((e) => {
          let course = this.courseList.find((g) => g.courseId === e.id);
          if (index <= 0) {
            totalAmount += this.form.lessonCount * course.lessonHours * course.unitPrice;
          }
          index++;
        });
      let generalDiscount = parseFloat(
        this.$appSetting.getGenericValue("BOOKLET_DISCOUNT_FROM_COURSE", 0.1)
      );
      return totalAmount * generalDiscount;
    },
    handleMoveUpItem(index) {
      if (index <= 0) return;
      let delItems = this.form.items.splice(index, 1);
      this.form.items.splice(index - 1, 0, delItems[0]);
    },
    handleMoveDownItem(index) {
      if (index >= this.form.items.length - 1) return;
      let delItems = this.form.items.splice(index, 1);
      this.form.items.splice(index + 1, 0, delItems[0]);
    },
    calculateItemTotal(item) {
      item.total = item.unitPrice * item.quantity;
      return item.total;
    },
    calculateItemGST(item) {
      item.gstAmount = item.unitPrice * item.quantity * (0.01 * item.gst);
      return item.gstAmount;
    },
    calculateNetTotal() {
      let netTotal = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        netTotal += item.unitPrice * item.quantity;
      }
      this.form.netTotal = netTotal;
      return this.form.netTotal;
    },
    calculateDiscountAmount() {
      let discountAmount = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        discountAmount =
          discountAmount +
          item.unitPrice * item.quantity * (0.01 * item.discount) +
          item.unitPrice *
          item.quantity *
          (1 - 0.01 * item.discount) *
          (0.01 * item.successiveDiscount);
      }
      this.form.discountAmount = discountAmount;
      return this.form.discountAmount;
    },
    getCombinedItemsCount() {
      return this.combinedItemsCount;
    },
    getCombinedItems() {
      let combinedItems = JSON.parse(JSON.stringify(this.form.items || []));
      let netTotalNeedToDiscount = 0;
      let netTotalNeedToSuccessiveDiscount = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        let itemTotal = item.unitPrice * item.quantity;
        item["titleHTML"] = `${item.title}${item.term ? `, ${item.term}` : ""}`;
        item["totalHTML"] = item.total ? this.$formatter.formatDecimal(item.total) : "";
        if (item.discount) {
          netTotalNeedToDiscount += itemTotal;
        }

        if (item.successiveDiscount) {
          netTotalNeedToSuccessiveDiscount += itemTotal * (1 - 0.01 * item.discount);
        }
      }
      // insert a new empty line
      // combinedItems.push({});
      if (this.form.discountItems) {
        let discountItems = JSON.parse(JSON.stringify(this.form.discountItems));
        for (let j = 0; j < discountItems.length; j++) {
          let discountAmount = 0;
          let discountItem = discountItems[j];
          if (!discountItem.isSuccessive) {
            discountAmount = netTotalNeedToDiscount * (0.01 * discountItem.discount);
          } else {
            discountAmount =
              netTotalNeedToSuccessiveDiscount * (0.01 * discountItem.discount);
          }

          let name = discountItem.name.split(/(?=[A-Z])/).join(" ");
          let item = {
            id: discountItem.name,
            title: `${name}(${discountItem.discount}%)`,
            titleHTML: `${name}(${discountItem.discount}%)`,
            type: "Discount",
            term: "",
            unitPrice: -1 * discountAmount,
            quantity: 1,
            comment: "",
            gst: 0,
            total: -1 * discountAmount,
            totalHTML: discountAmount
              ? this.$formatter.formatDecimal(-1 * discountAmount)
              : "",
            gstAmount: 0,
            successiveDiscount: 0,
            discount: 0,
          };

          combinedItems.push(item);
        }
      }

      if (this.form.enableRedeemPoints) {
        let redeemedAmount = this.form.redeemedAmount;
        let redeemedPoints = this.form.redeemedPoints;
        let item = {
          id: "Redeem from Points",
          title: `Redeemed $${redeemedAmount} from ${redeemedPoints} points`,
          titleHTML: `Redeemed $${redeemedAmount} from ${redeemedPoints} points`,
          type: "Redeem",
          term: "",
          unitPrice: redeemedAmount,
          quantity: 1,
          comment: "",
          gst: 0,
          total: redeemedAmount,
          totalHTML: redeemedAmount
            ? `-${this.$formatter.formatDecimal(redeemedAmount)}`
            : "",
          gstAmount: 0,
          successiveDiscount: 0,
          discount: 0,
        };
        combinedItems.push(item);
      }

      if (this.form.creditDeduction && this.form.creditDeduction > 0) {
        let creditDeduction = this.form.creditDeduction;
        let item = {
          id: "Credit Deduction",
          title: "Credit Deduction",
          titleHTML: "Credit Deduction",
          type: "Redeem",
          term: "",
          unitPrice: creditDeduction,
          quantity: 1,
          comment: "",
          gst: 0,
          total: creditDeduction,
          totalHTML: creditDeduction
            ? `-${this.$formatter.formatDecimal(creditDeduction)}`
            : "",
          gstAmount: 0,
          successiveDiscount: 0,
          discount: 0,
        };
        combinedItems.push(item);
      }

      combinedItems.push({});
      if (this.form.pointsItems) {
        let totalPoints = 0;
        this.form.pointsItems.forEach((pointsItem) => {
          let item = {
            id: pointsItem.name,
            title: pointsItem.title,
            titleHTML: pointsItem.title,
            type: "Points",
            term: "",
            unitPrice: pointsItem.points,
            quantity: 1,
            comment: "",
            gst: 0,
            total: pointsItem.points,
            totalHTML: pointsItem.points ? `${pointsItem.points} points` : "",
            gstAmount: 0,
            successiveDiscount: 0,
            discount: 0,
          };
          totalPoints += pointsItem.points;

          combinedItems.push(item);
        });

        if (totalPoints) {
          let item = {
            id: "TotalPoints",
            title: "Total points",
            titleHTML: "Total points",
            type: "TotalPoints",
            term: "",
            unitPrice: totalPoints,
            quantity: 1,
            comment: "",
            gst: 0,
            total: totalPoints,
            totalHTML: `<span style='color:red;'>${totalPoints}</span> points`,
            gstAmount: 0,
            successiveDiscount: 0,
            discount: 0,
          };

          combinedItems.push(item);
        }
      }

      this.combinedItemsCount = combinedItems.length;
      return combinedItems;
    },
    calculateTotal() {
      let total = 0;
      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        total +=
          item.unitPrice *
          item.quantity *
          (1 - 0.01 * item.discount) *
          (1 - 0.01 * item.successiveDiscount);
      }

      this.form.gstTotal = total * (0.01 * this.form.gst);
      if (this.form.enableRedeemPoints && this.form.redeemedAmount > 0) {
        total = total - this.form.redeemedAmount;
      }
      if (this.form.creditDeduction && this.form.creditDeduction > 0) {
        total = total - this.form.creditDeduction;
      }
      this.form.total = Math.round(total);
      return this.form.total;
    },
    printPreview() {
      if (this.validateData()) {
        this.step = 2;
      } else {
        this.$message.error("Validation failed, please correct!");
        return false;
      }
    },
    validateData() {
      return true;
    },
    previousStep() {
      this.step = 1;
    },
    addPromotionDiscount(promotionType, checked) {
      console.log("addPromotionDiscount", promotionType, checked);
      let promotion = this.promotionItems.find((e) => {
        return e.name === promotionType;
      });

      if (promotion) {
        this.addOrRemoveDiscountItem(
          promotion.name,
          promotion.isSuccessive,
          checked ? promotion.discount : 0
        );
        if (checked === true) {
          this.form.promotionType = promotion.name;
          this.form.discount += promotion.discount;
        } else {
          this.form.promotionType = "";
          this.form.discount -= promotion.discount;
          if (this.form.discount < 0) this.form.discount = 0;
        }

        this.handleDiscountChange();
      }
    },
    addOrUpdateSuccessiveDiscountItem(name, isSuccessive, discount) {
      this.addOrRemoveDiscountItem(name, isSuccessive, discount);
      this.form.successiveDiscount = 0;
      for (let i = 0; i < this.form.discountItems.length; i++) {
        let discountItem = this.form.discountItems[i];
        if (discountItem.isSuccessive === true) {
          this.form.successiveDiscount += discountItem.discount;
        }
      }

      for (let i = 0; i < this.form.items.length; i++) {
        let item = this.form.items[i];
        let specialItem = this.specialItems.find((e) => {
          return e.id === item.id;
        });
        if (!specialItem || specialItem.useGlobalSuccessiveDiscount) {
          item.successiveDiscount = this.form.successiveDiscount;
        }
      }
    },
    addOrRemoveDiscountItem(name, isSuccessive, discount) {
      this.form.discountItems = this.form.discountItems || [];
      let tempArr = this.form.discountItems.filter((element) => {
        return element.name == name;
      });
      if (tempArr.length > 0) {
        tempArr[0].discount = discount;
      } else {
        this.form.discountItems.push({
          name: name,
          isSuccessive: isSuccessive,
          discount: discount,
        });
      }
      if (discount <= 0) {
        let index = this.form.discountItems.findIndex((element) => {
          return element.name == name;
        });
        if (index >= 0) {
          this.form.discountItems.splice(index, 1);
        }
      }
    },
    saveAndPrintReceipt() {
      this.form.operator = this.$user.getUsername();
      let courseArr = [];
      this.form.items.forEach((item) => {
        courseArr.push(
          `${item.title}/${item.term}/Qty:${item.quantity}/$${item.unitPrice}`
        );
      });
      if (!this.form.studentId) {
        this.$message.error("Please select a student.");
        return;
      }
      if (!this.form.promotionType) {
        this.form.promotionType = "Normal";
      }
      if (!this.form.loyaltyYears) {
        this.form.loyaltyYears = 0;
      }
      for (let i = 0; i < this.form.items; i++) {
        let item = this.form.items[i];
        if (!item.term) {
          item.term = this.form.term;
        }
      }
      this.form.description = courseArr.join("|");
      if (this.form.receiptId) {
        putData(this.controllerUrl, this.form.receiptId, this.form).then((res) => {
          console.log(res);
          if (res.result && res.code === "200") {
            this.$message.info("Save receipt successfully");
            this.printReceipt();
          } else {
            this.$message.error("Update failed, error message: " + res.message);
          }
        });
      } else {
        postData(this.controllerUrl, this.form).then((res) => {
          console.log(res);
          if (res.result && res.code === "200") {
            this.$message.info("Save receipt successfully");
            this.printReceipt();
          } else {
            this.$message.error("Create failed, error message: " + res.message);
          }
        });
      }
    },
    getLoyaltyYears(enrolledTime) {
      let enrolledDate = new Date(enrolledTime || this.student.enrolledTime);
      if (!enrolledDate) return 0;
      let now = new Date();
      let diffInTime = now.getTime() - enrolledDate.getTime();
      let diffInDays = diffInTime / (1000 * 3600 * 24);
      this.form.loyaltyYears = Math.floor(diffInDays / 365);
      return this.form.loyaltyYears;
    },
    getLoyaltyYearsName() {
      let loyaltyYears = this.form.loyaltyYears;
      let name = null;
      if (loyaltyYears >= 5) {
        name = "LOYALTY_FOR_FIVE_YEAR";
      } else if (loyaltyYears == 4) {
        name = "LOYALTY_FOR_FOUR_YEAR";
      } else if (loyaltyYears == 3) {
        name = "LOYALTY_FOR_THREE_YEAR";
      } else if (loyaltyYears == 2) {
        name = "LOYALTY_FOR_TWO_YEAR";
      } else if (loyaltyYears == 1) {
        name = "LOYALTY_FOR_ONE_YEAR";
      }
      return name;
    },
    printReceipt() {
      const prtHtml = document.getElementById("printArea").outerHTML;
      let title = `${this.form.billTo}-${this.form.term}-${this.$formatter.formatDate(
        this.form.billDate,
        "yyyyMMdd"
      )}`;
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
          <title>${title}</title>
<style>
@media print {
  body {
    margin:0;
    -webkit-print-color-adjust: exact;
  }
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
html{
  font-size: 16px;
}
.printArea {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  font-size: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.printArea .header {
  width: 100%;
  background-color: #fff;
}
.printArea .header .leftPart {
  float: left;
}
.printArea .header .leftPart .companyName {
  color: #2c3a65;
  font-size: 2.34rem;
  line-height: 62px;
}
.printArea .header .rightPart {
  float: right;
}
.printArea .header .rightPart .templateName {
  float: left;
  color: #7a8dc5;
  font-size: 2.84rem;
  font-weight: 700;
  line-height: 64px;
}
.printArea .header .rightPart .logo {
  float: left;
}
.printArea .header .rightPart .logo img {
  width: 60px;
  height: 58px;
  margin-left: 10px;
}
.printArea .header .rightPart .billToDate {
  margin-top: 20px;
  float: right;
}
.printArea .header .rightPart .billToDate .date {
  color: #000;
  text-align: center;
  border: 0.5pt solid #a6a6a6;
  padding: 2px 20px;
}
.printArea .billTo {
  width: 100%;
  margin-top: 30px;
}
.printArea .billTo .title {
  color: white;
  font-size: 1.08rem;
  font-weight: 700;
  text-align: left;
  background: #3b4e87;
  padding-left: 10px;
  width: 35%;
}
.printArea .billTo .name {
  padding-left: 10px;
}

.printArea .items {
  margin-top: 50px;
}
.printArea .items table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: 0.5pt solid #3b4e87;
  border-right-width: 0;
}

.printArea .items table tr {
  line-height: 27px;
  height: 27px;
}
.printArea .items table tr th {
  color: white;
  font-size: 1.08rem;
  font-weight: 700;
  text-align: center;
  background: #3b4e87;
  border-right: 0.5pt solid #3b4e87;
}
.printArea .items table tr td {
  text-align: center;
  border-right: 0.5pt solid #3b4e87;
  font-weight: 400;
}
.printArea .items table tr.odd {
  background: #f2f2f2;
}
.printArea .summary {
  margin-top: 30px;
  width: 100%;
}
.printArea .summary .leftPart {
  float: left;
  width: 45%;
  height: 60px;
  border-collapse: collapse;
  table-layout: fixed;
  border: 0.5pt solid #3b4e87;
}
.printArea .summary .leftPart .title {
  color: white;
  font-size: 1.08rem;
  font-weight: 700;
  padding-left: 10px;
  background: #3b4e87;
}
.printArea .summary .leftPart ul {
  padding-left: 10px;
  list-style-type: none;
}
.printArea .summary .rightPart {
  float: right;
  width: 40%;
  border-top: 0.5pt solid #3b4e87;
  height: 148px;
}
.printArea .summary .rightPart table {
  width: 100%;
}
.printArea .summary .rightPart table tr td {
  width: 50%;
  font-size: 1rem;
  font-weight: 400;
}
.printArea .summary .rightPart table tr td.value {
  text-align: right;
}
.printArea .summary .rightPart table tr td.discount {
  font-size: 1.5rem;
  font-weight: 700;
}
.printArea .summary .rightPart table tr td.total {
  font-size: 1.67rem;
  font-weight: 700;
}
.printArea .summary .rightPart .paymentMethods {
  width: 100%;
  margin-top: 20px;
  height: 60px;
  border: 0.5pt solid #3b4e87;
  display: none;
}
.printArea .summary .rightPart .paymentMethods .title {
  color: white;
  font-size: 1.08rem;
  font-weight: 700;
  padding-left: 10px;
  text-align: center;
  background: #3b4e87;
}
.printArea .summary .rightPart .paymentMethods ul {
  padding-left: 10px;
  list-style-type: none;
}
.printArea .footer {
  margin-top: 50px;
  width: 100%;
}
.printArea .footer .container {
  width: 45%;
  padding: 0;
  margin: 0 auto;
  border-width: 0;
  text-align: center;
}
.printArea .footer .contactDetail {
  width: 100%;
}
.printArea .footer .compliment {
  width: 100%;
  font-size: 1.34rem;
  font-weight: 700;
  font-style: italic;
  text-align: center;
}
.printArea .footer::after,
.printArea .summary::after,
.printArea .header::after,
.printArea .header .rightPart .templateAndLog::after {
  content: "";
  display: block;
  height: 0;
  font: 0px/0 serif;
  clear: both;
  overflow: hidden;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .printArea {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
</style>
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //WinPrint.close();
    },
    handleEnableRoyalPointsChange() {
      let name = this.getLoyaltyYearsName();
      if (name) {
        if (this.form.enableRoyalPoints) {
          let item = this.options.pointsTemplateList.find((e) => e.name === name);
          if (item) {
            this.addOrUpdateIssuedPointsItem(item);
          }
        } else {
          this.removeIssuedPointsItem(name);
        }
      }
    },
    handleEnableRoyalDiscountChange() {
      if (this.form.enableRoyalDiscount) {
        this.addLoyaltyYearsDiscount();
      } else {
        this.addOrUpdateSuccessiveDiscountItem(this.royalDiscountName, true, 0);
      }
    },
    handleLessonCountChange() {
      this.handleItemChange();

      this.form.items
        .filter((e) => e.type === "Course")
        ?.forEach((e) => {
          let course = this.courseList.find((g) => g.courseId === e.id);
          e.quantity = this.form.lessonCount * course.lessonHours;
        });
    },
    handleSpecialPointsChange(name, enabled, fromPointsTemplate = true, val = null) {
      if (enabled) {
        let item = null;
        if (fromPointsTemplate) {
          item = this.options.pointsTemplateList.find((e) => e.name === name);
        } else {
          item = this.specialPointsItem.find((e) => e.name === name);
          item.points = val || item.calculatePoints();
          this.form[item.fieldInForm] = item.points;
        }

        if (item) {
          this.addOrUpdateIssuedPointsItem(item);
        }
      } else {
        this.form.enrolmentPoints = this.calculateEnrolmentPoints();
        this.removeIssuedPointsItem(name);
      }
    },
    addOrUpdateIssuedPointsItem(item) {
      let pos = -1;
      if ((pos = this.form.pointsItems.findIndex((e) => e.name === item.name)) >= 0) {
        this.form.pointsItems[pos].points = item.points;
        return;
      }
      this.form.pointsItems.push(item);
    },
    removeIssuedPointsItem(name) {
      if (!name) return;
      let index = this.form.pointsItems.findIndex((e) => e.name === name);
      if (index < 0) return;
      this.form.pointsItems.splice(index, 1);
    },
    calculateEnrolmentPoints() {
      let courseList = this.form.items.filter((e) => e.type === "Course");
      if (courseList && courseList.length > 0) {
        let pointsCalculateDiscountForReplay = parseFloat(
          this.$appSetting.getGenericValue("POINTS_CALCULATE_DISCOUNT_FOR_REPLAY", "0.5")
        );
        let pointsCalculateDiscountForHomework = parseFloat(
          this.$appSetting.getGenericValue(
            "POINTS_CALCULATE_DISCOUNT_FOR_HOMEWORK",
            "0.3"
          )
        );
        let pointsCalculateDiscountForAnswer = parseFloat(
          this.$appSetting.getGenericValue("POINTS_CALCULATE_DISCOUNT_FOR_ANSWER", "0.2")
        );
        let totalPoints = 0;
        courseList.forEach((e) => {
          let course = this.courseList.find((g) => g.courseId === e.id);
          totalPoints =
            totalPoints +
            pointsCalculateDiscountForReplay *
            this.form.lessonCount *
            course.lessonHours *
            e.unitPrice;
          totalPoints =
            totalPoints +
            pointsCalculateDiscountForHomework *
            this.form.lessonCount *
            course.lessonHours *
            e.unitPrice;
          totalPoints =
            totalPoints +
            pointsCalculateDiscountForAnswer *
            this.form.lessonCount *
            course.lessonHours *
            e.unitPrice;
        });

        return Math.ceil(totalPoints);
      }
    },
    rebuildCourseLessonCountList(lessonCount) {
      this.options.lessonCountList = [];
      let i = 0;
      for (; i < lessonCount; i++) {
        this.options.lessonCountList.push({ key: i + 1, value: i + 1 });
      }
      this.form.lessonCount = i;
    },
    handleRedeemPointsChange(val) {
      // 500 points = $25
      let redeemPointsRate = parseFloat(this.$appSetting.getGenericValue(
        "ENROLMENT_REDDEM_POINTS_RATE",
        20
      ));
      let redeemedAmount = Math.round(val / redeemPointsRate);
      console.log("handleRedeemPointsChange", redeemedAmount);
      this.form.redeemedAmount = redeemedAmount;
    },
    handleCreditDeductionChange(val) {
      this.form.creditDeduction = val;
    },
  },
};
</script>
<style slot-scope>
.settingTemplate .studentInput {
  float: left;
  cursor: default;
}

.settingTemplate .searchStudentBtn {
  float: right;
  width: 20%;
}

.settingTemplate .studentInfoPanel {
  margin-top: 10px;
  padding: 20px;
  background-color: #d3d3d3;
}

.discountItems {
  margin-top: 20px;
}

.courseTemplate {
  margin-top: 20px;
}

.courseTemplate .courseSelect {
  margin-top: 20px;
  width: 30%;
}

.courseTemplate h2 {
  font-size: 1.34rem;
  font-weight: 700;
}

.courseTemplate .courseListTable {
  margin-top: 10px;
}

.courseTemplate .courseListTable .courseNameInput {
  float: left;
  width: 30%;
}

.courseTemplate .courseListTable .termSelect {
  float: left;
  width: 30%;
}

.courseTemplate .courseListTable .commentInput {
  float: right;
  width: 40%;
}
</style>
<style id="printCSS">
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  font-size: 12pt;
}

.printArea {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  font-size: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.printArea .header {
  width: 100%;
  background-color: #fff;
}

.printArea .header .leftPart {
  float: left;
}

.printArea .header .leftPart .companyName {
  color: #2c3a65;
  font-size: 2.34rem;
  line-height: 62px;
}

.printArea .header .rightPart {
  float: right;
}

.printArea .header .rightPart .templateName {
  float: left;
  color: #7a8dc5;
  font-size: 2.84rem;
  font-weight: 700;
  line-height: 64px;
}

.printArea .header .rightPart .logo {
  float: left;
}

.printArea .header .rightPart .logo img {
  width: 60px;
  height: 58px;
  margin-left: 10px;
}

.printArea .header .rightPart .billToDate {
  margin-top: 20px;
  float: right;
}

.printArea .header .rightPart .billToDate .date {
  color: #000;
  text-align: center;
  border: 0.5pt solid #a6a6a6;
  padding: 2px 20px;
}

.printArea .billTo {
  width: 100%;
  margin-top: 30px;
}

.printArea .billTo .title {
  color: white;
  font-size: 1.08rem;
  font-weight: 700;
  text-align: left;
  background: #3b4e87;
  padding-left: 10px;
  width: 35%;
}

.printArea .billTo .name {
  padding-left: 10px;
}

.printArea .items {
  margin-top: 50px;
}

.printArea .items table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border: 0.5pt solid #3b4e87;
  border-right-width: 0;
}

.printArea .items table tr {
  line-height: 27px;
  height: 27px;
}

.printArea .items table tr th {
  color: white;
  font-size: 1.08rem;
  font-weight: 700;
  text-align: center;
  background: #3b4e87;
  border-right: 0.5pt solid #3b4e87;
}

.printArea .items table tr td {
  text-align: center;
  border-right: 0.5pt solid #3b4e87;
  font-weight: 400;
}

.printArea .items table tr.odd {
  background: #f2f2f2;
}

.printArea .summary {
  margin-top: 30px;
  width: 100%;
}

.printArea .summary .leftPart {
  float: left;
  width: 45%;
  height: 60px;
  border-collapse: collapse;
  table-layout: fixed;
  border: 0.5pt solid #3b4e87;
}

.printArea .summary .leftPart .title {
  color: white;
  font-size: 1.08rem;
  font-weight: 700;
  padding-left: 10px;
  background: #3b4e87;
}

.printArea .summary .leftPart ul {
  padding-left: 10px;
  list-style-type: none;
}

.printArea .summary .rightPart {
  float: right;
  width: 40%;
  border-top: 0.5pt solid #3b4e87;
  height: 148px;
}

.printArea .summary .rightPart table {
  width: 100%;
}

.printArea .summary .rightPart table tr td {
  width: 50%;
  font-size: 1rem;
  font-weight: 400;
}

.printArea .summary .rightPart table tr td.value {
  text-align: right;
}

.printArea .summary .rightPart table tr td.discount {
  font-size: 1.5rem;
  font-weight: 700;
}

.printArea .summary .rightPart table tr td.total {
  font-size: 1.67rem;
  font-weight: 700;
}

.printArea .summary .rightPart .paymentMethods {
  width: 100%;
  margin-top: 20px;
  height: 60px;
  border: 0.5pt solid #3b4e87;
  display: none;
}

.printArea .summary .rightPart .paymentMethods .title {
  color: white;
  font-size: 1.08rem;
  font-weight: 700;
  padding-left: 10px;
  text-align: center;
  background: #3b4e87;
}

.printArea .summary .rightPart .paymentMethods ul {
  padding-left: 10px;
  list-style-type: none;
}

.printArea .footer {
  margin-top: 50px;
  width: 100%;
}

.printArea .footer .container {
  width: 45%;
  padding: 0;
  margin: 0 auto;
  border-width: 0;
  text-align: center;
}

.printArea .footer .contactDetail {
  width: 100%;
}

.printArea .footer .compliment {
  width: 100%;
  font-size: 1.34rem;
  font-weight: 700;
  font-style: italic;
  text-align: center;
}

.printArea .footer::after,
.printArea .summary::after,
.printArea .header::after,
.printArea .header .rightPart .templateAndLog::after {
  content: "";
  display: block;
  height: 0;
  font: 0px/0 serif;
  clear: both;
  overflow: hidden;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .printArea {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
</style>
